import { ErrorBase } from "@core/models/error.model";


export class RegisterErrorModel {
  public static ERROR_CODE = {
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    ACCOUNT_ALREADY_EXIST: 'ACCOUNT_ALREADY_EXIST',
    ACCOUNT_INACTIVE: 'ACCOUNT_INACTIVE',
    ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
  };

  public static handle(error: ErrorBase) {
    switch (error.code) {
      case -1:
      case -2:
        throw new Error(RegisterErrorModel.ERROR_CODE.ACCOUNT_ALREADY_EXIST);
      case -3:
        throw new Error(RegisterErrorModel.ERROR_CODE.ACCOUNT_INACTIVE);
      case -4:
        throw new Error(RegisterErrorModel.ERROR_CODE.ACCOUNT_LOCKED);
      default:
        break;
    }
    throw new Error(RegisterErrorModel.ERROR_CODE.INTERNAL_SERVER_ERROR);
  }
}
