<section class="d-flex flex-column justify-content-between h-100" *transloco="let translated;">
  <div class="box">
    <div class="guest__layout-wrapper-logo text-center mb-6">
      <a href="javascript:void(0)"
        class="page-logo-link press-scale-down d-flex align-items-center flex-column cursor-default">
        <img src="assets/img/logo-big.png" alt="HUTECH WebApp" aria-roledescription="logo">
        <span class="page-logo-text text-center fw-500 text-dark mt-3">HUTECH</span>
      </a>
    </div>



    <div class="guest__layout-wrapper-heading text-center">
      <h1 class="h1">Đăng ký tài khoản</h1>
      <p class="text-muted">Đăng ký tài khoản để bắt đầu làm việc.</p>
    </div>

    <hr>

    <div class="guest__layout-wrapper-login">

      <!--    Display error code when login by token failure-->
      <!-- @if (this.switchCaseErrorCode) { @switch (this.switchCaseErrorCode) { @case
      (0) { } @case (ERROR_CODE.AccountNotAllowLoginWithTypeError) {
      <div class="alert alert-danger" role="alert">
        <strong transloco="auth.component.login.errors.AccountNotAllowLoginWithTypeError">
          Loại tài khoản không hợp lệ: Vui lòng chọn loại tài khoản đúng với tài
          khoản của bạn
        </strong>
      </div>
      } @case (ERROR_CODE.AccessTokenExpiredError) {
      <div class="alert alert-danger" role="alert">
        <strong transloco="auth.component.login.errors.AccessTokenExpiredError">
          Phiên đăng nhập này đã hết hạn, vui lòng thực hiện đăng nhập để tiếp tục
          sử dụng ứng dụng
        </strong>
      </div>
      } @case (ERROR_CODE.AccountTypeAndTokenNotMatchError) {
      <div class="alert alert-danger" role="alert">
        <strong transloco="auth.component.login.errors.AccountTypeAndTokenNotMatchError">
          Liên kết không hợp lệ: Tài khoản sinh viên không thể đăng nhập vào cổng
          thông tin giành cho nhân viên và ngược lại
        </strong>
      </div>
      } @case (ERROR_CODE.AccountInactiveError) {
      <div class="alert alert-danger" role="alert">
        <strong transloco="auth.component.login.errors.AccountInactiveError">
        </strong>
        <u (click)="navigateToActiveAccountPage()" style="cursor: pointer">
          <i>
            <b>
              <br />
              Gửi lại tôi đường dẫn kích hoạt tài khoản
            </b>
          </i>
        </u>
      </div>
      } @case (ERROR_CODE.AccountLockedError) {
      <div class="alert alert-danger" role="alert">
        <strong transloco="auth.component.login.errors.AccountLockedError">
        </strong>
        @if(this.displayAccoutLockMessage) {
        <span><br> Chi tiết lỗi: {{ displayAccoutLockMessage }} </span>
        }
      </div>
      } @case (ERROR_CODE.AccountNotExistedError) {
      <div class="alert alert-danger" role="alert">
        <strong transloco="auth.component.login.errors.AccountNotExistedError">
        </strong>
      </div>
      } @case (ERROR_CODE.WrongUsernameOrPasswordError) {
      <div class="alert alert-danger" role="alert">
        <strong transloco="auth.component.login.errors.WrongUsernameOrPasswordError">
        </strong>
      </div>
      } @case (ERROR_CODE.NotAllowAccountUseAppError) {
      <div class="alert alert-danger" role="alert">
        <strong transloco="auth.component.login.errors.NotAllowAccountUseAppError">
          Tài khoản của bạn không được phép sử dụng ứng dụng này
        </strong>
      </div>
      } @case (ERROR_CODE.AccountNotAllowUseFeatureError) {
      <div class="alert alert-danger" role="alert">
        <strong transloco="auth.component.login.errors.AccountNotAllowUseFeatureError">
          Tài khoản của bạn không được phép sử dụng tính năng này
        </strong>
      </div>
      } @case (ERROR_CODE.AccountDeletedError) {
      <div class="alert alert-danger" role="alert">
        <strong transloco="auth.component.login.errors.AccountDeletedError">
        </strong>
      </div>
      } @default {
      <div class="alert alert-danger" role="alert">
        <strong>
          Lỗi kỹ thuật: Không thể đăng nhập, vui lòng đăng nhập lại để tiếp tục sử
          dụng ứng dụng
        </strong>
      </div>
      } } } -->
      <!--    Display error code when login by token failure-->

      <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
        <div class="form-group row">
          <label class="col-xl-12 form-label" for="firstName">
            Họ và tên của bạn
            <span class="text-danger"> *</span>
          </label>

          <div class="col-4 pr-1">
            <input zaaAutofocus type="text" formControlName="firstName" id="firstName"
              class="form-control form-control-lg" placeholder="Họ" required>
            <div *ngIf="registrationForm.get('firstName')?.hasError('required')
                 && this.registrationForm.get('firstName')?.touched" class="invalid-feedback d-block">
              Họ không được để trống
            </div>
          </div>

          <div class="col-4 pl-1 pr-1">
            <input type="text" formControlName="middleName" id="middleName" class="form-control form-control-lg"
              placeholder="Tên lót">
          </div>

          <div class="col-4 pl-1">
            <input type="text" formControlName="lastName" id="lastName" class="form-control form-control-lg"
              placeholder="Tên" required>
            <div *ngIf="registrationForm.get('lastName')?.hasError('required')
                 && this.registrationForm.get('lastName')?.touched" class="invalid-feedback d-block">
              Tên không được để trống
            </div>
          </div>
        </div>

        <!-- INPUT EMAIL REGION -->
        <div class="form-group">
          <label class="form-label" for="userpassword">Email Đăng nhập
            <span class="text-danger"> *</span>
          </label>
          <input type="email" formControlName="email" id="userpassword" class="form-control form-control-lg" required>

          <ng-container *ngIf="registrationForm.get('email')?.touched">
            <div *ngIf="registrationForm.get('email')?.hasError('required')" class="invalid-feedback d-block">
              Email đăng nhập không được để trống
            </div>

            <div *ngIf="registrationForm.get('email')?.hasError('email')" class="invalid-feedback d-block">
              Vui lòng nhập đúng định dạng địa chỉ email <strong>{{ 'vidu@gmail.com'}}</strong>
            </div>

            <div *ngIf="this.showAccountAlreadyExistError" class="invalid-feedback d-block">
              Tài khoản này đã tồn lại, vui lòng nhập tên tài khoản khác
            </div>

            <div *ngIf="this.showInActiveAccountError" class="invalid-feedback d-block">
              Tài khoản này đã được đăng ký trước đó nhưng chưa được kích hoạt
            </div>

            <div *ngIf="this.showLockedAccountError" class="invalid-feedback d-block">
              Tài khoản này đã bị khóa
            </div>
          </ng-container>
        </div>
        <!--END INPUT EMAIL REGION -->

        <div class="form-group">
          <app-input-password [class]="'form-control form-control-lg'"
            [value]="this.registrationForm.get('password')?.value ?? ''"
            (changePassword)="this.onChangePassword($event)">
          </app-input-password>
        </div>

        <!-- 2 CÁI CHECK BOX NE -->
        <!-- TODO: THONG TIN NAY SE TUY THUOC VAO NGHIEM VU KHAC NHAU NEN VIET TEMPLATE -->
        <div class="form-group demo mb-5">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" formControlName="terms" class="custom-control-input" id="terms" required>
            <label class="custom-control-label cursor-pointer" for="terms"> Tôi đồng ý với việc thu thập và lưu trữ dữ
              liệu cá nhân theo
              <a [href]="this.policyLink" target="_blank">
                <u>
                  cam kết bảo mật
                </u>
              </a>
            </label>
          </div>
          <!-- <div class="custom-control custom-checkbox">
            <input type="checkbox"
                   formControlName="newsletter"
                   class="custom-control-input"
                   id="newsletter"
                   required>
            <label class="custom-control-label cursor-pointer"
                   for="newsletter">
              Tôi muốn nhận thông báo về các ấn phẩm và tin tức mới nhất của Tạp chí
            </label>
          </div> -->
        </div>
        <div class="form-group">
          <re-captcha (resolved)="resolved($event)" [siteKey]="this.reCapchaSecretKey">
          </re-captcha>
        </div>
        <!-- 2 CÁI CHECK BOX NE -->

        <!-- # Submit -->

        <div class="row">
          <div class="col-12">

          </div>
        </div>
        <div class="row no-gutters justify-content-start justify-content-sm-center">
          <button type="button" (click)="this.navigateToLoginPage()" [disabled]="this.waitingToRegis"
            class="mr-3 col-3 btn-lg btn btn-outline-default waves-effect waves-themed" [ngClass]="{
              'cursor-default': this.waitingToRegis,
            }">
            <i class="fal fa-long-arrow-left mr-2"></i>
            Quay lại
          </button>

          <button type="submit" [disabled]="this.registrationForm.invalid || this.waitingToRegis" [ngClass]="{
            'cursor-default': this.registrationForm.invalid || this.waitingToRegis,
          }" class="col col-md-4 btn-lg btn btn-primary waves-effect waves-themed">
            <span *ngIf="this.waitingToRegis" class="spinner-border spinner-border-sm mr-1" role="status"
              aria-hidden="true">
            </span>
            Đăng ký
          </button>
        </div>
      </form>

    </div>


    <hr>

  </div>

  <div class="copyright mt-6">
    <div class="p-3 text-center">
      HUTECH © 2012 - 2023 thuộc <a href="https://qlcntt.hutech.edu.vn/" class="text-info fw-500"
        title="qlcntt.hutech.edu.vn" target="_blank">Viện TTQLCNTT</a>
    </div>
  </div>

</section>
