import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, isDevMode } from '@angular/core';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AppRoutingService } from '@core/routing/app-routing.service';
import { InputPasswordComponent } from '@features/auth/shared/components/input-password/input-password.component';
import { take, finalize } from 'rxjs';
import { IRegister } from '../interfaces/register-info.interface';
import { RegisterErrorModel } from '../../../core/domain/register/register-error.model';
import { Router } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha';
import { environment } from '@config/environments/environment';
import { LINK_NAVIGATE_TO_POLICY_WHEN_REGISTER } from '@features/auth/const';
import { AuthService } from '@features/auth/core/domain/login/auth-version-2.service';
import { TranslocoModule } from '@ngneat/transloco';
import { ZaaAutofocusDirective } from '@shared/directives/zaa-autofocus/zaa-autofocus.directive';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputPasswordComponent,
    RecaptchaModule,
    TranslocoModule,
    ZaaAutofocusDirective,
  ],
  providers: [],
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  // Dependency
  private readonly formBuilder = inject(FormBuilder);
  private readonly routing = inject(Router);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly registerService = inject(AuthService);

  public reCapchaSecretKey = environment.authen.reCapchaSecretKey ?? '';
  // Internal data
  registrationForm!: FormGroup;
  policyLink = LINK_NAVIGATE_TO_POLICY_WHEN_REGISTER;
  public waitingToRegis = false;

  // display error message:
  showAccountAlreadyExistError: boolean = false;
  showInActiveAccountError: boolean = false;
  showLockedAccountError: boolean = false;
  showInternalServerError: boolean = false; // <<--

  // Angular lifecycle
  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.registrationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      terms: [true, Validators.requiredTrue],
      newsletter: [true, Validators.requiredTrue],
      reCapcha: [false, Validators.requiredTrue], // <--- Change false in test
    });
  }

  // Event handler
  onSubmit() {
    if (this.registrationForm.valid) {
      // Ẩn mấy lỗi đi khi click đăng ký - nếu có lổi thì nó hiện lại
      this.showAccountAlreadyExistError = false;
      this.showInActiveAccountError = false;
      this.showLockedAccountError = false;

      this.waitingToRegis = true;
      this.registrationForm.disable();

      const registerData = this.registrationForm.getRawValue() as IRegister;
      this.registerService
        .register(registerData)
        .pipe(
          take(1),
          finalize(() => {
            this.waitingToRegis = false;
            this.registrationForm.enable();
          }),
        )
        .subscribe({
          next: (response) => {
            // Không success thì chỉ có error nên không cần if chổ này
            // Navigate to notify verify email if create account success.
            this.routing.navigateByUrl(
              `account-confirm/${registerData.email}/wait`,
            );
          },
          error: (err) => {
            this.handleErrorAfterRegister(err);
          },
        });
    }
  }

  onChangePassword(event: any) {
    this.registrationForm.get('password')?.setValue(event);
  }

  // Implement logic
  handleErrorAfterRegister(error: Error) {
    isDevMode() && console.error(error);

    switch (error.message) {
      case RegisterErrorModel.ERROR_CODE.ACCOUNT_ALREADY_EXIST:
        this.showAccountAlreadyExistError = true;
        break;

      case RegisterErrorModel.ERROR_CODE.ACCOUNT_INACTIVE:
        this.showInActiveAccountError = true;
        break;

      case RegisterErrorModel.ERROR_CODE.ACCOUNT_LOCKED:
        this.showLockedAccountError = true;
        break;

      default:
        this.showInternalServerError = true;
        break;
    }
  }

  resolved(captchaResponse: string | null) {
    this.registrationForm.get('reCapcha')?.setValue(true);
  }

  navigateToLoginPage() {
    this.appRoutingService.navigateToLoginPage();
  }
}
